import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;
export default async function SearchProduct(query) {
  try {
    const response = await axios.get(
      `${url}/search/product?query=${query}`
    );
    console.log(response, "searchresponse ");
    return response;
  } catch (error) {
    console.log(error, "error ");
  }
}
SearchProduct()