import React from 'react';
import Pagination from '@mui/material/Pagination';
import './pages.css';
import banner from '../../../assets/b1.jpg';
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ValueIcon06 from '../../../assets/rating-5star.png';     
import LeftArrow from '../../../assets/left-arrow.svg';   
import RightArrow from '../../../assets/right-arrow.svg';   


export default function Testimonials() {
    const { t } = useTranslation();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 599,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
    

    const displayedTestimonials = [
        {
            id: 1,
            name: 'DAVID M',
            desginastion: 'Fleet Manager',
            image: banner,
            content: "Eco Fleet Services' DEF and lubricants have significantly improved our vehicle performance. Their on-site delivery service saves us valuable time and logistics costs. Highly recommend!",
        },
        {
            id: 2,
            name: 'Sarah M',
            desginastion: 'Operations Director',
            image: banner,
            content: "The quality of engine oil and antifreeze from Eco Fleet Services is top-notch. Their responsive customer service is outstanding. A great partner for our business!",
        },
        {
            id: 3,
            name: 'Gurshan S',
            desginastion: 'Logistics Coordinator',
            image: banner,
            content: "With Eco Fleet Services, we started saving significantly on lubricant costs. Their bulk refill program and timely deliveries are excellent. We rely on their expertise!",        },
        {
            id: 4,
            name: ' Saad R',
            desginastion: 'Fleet Supervisor',
            image: banner,
            content: "Eco Fleet Services' washer fluids and break cleaners are the best we've used. They ensure safety and efficiency for our fleet. Exceptional service and products!",
        },
    ];

    return (
        <div className='testimonials'>
        <div className='container'>
            <div className='testimonial-heading'>
                <h2 className='testi-header'>{t('Testimonials')}</h2>
            </div>
            <div className='row'>
                <Slider {...settings}>
                    {displayedTestimonials?.map((testimonial) => (
                        <div key={testimonial.id} className='testi-box'>
                            <div className='testi-user-img'>
                                <img src={testimonial.image} alt='User' className='border-rounded' width="100%" height="auto" />
                                <img src={ValueIcon06} alt='User' className='star-rating' width="100%" height="auto" />

                                {/* <img src={LeftArrow} alt='User' className='star-rating' width="100%" height="auto" /> */}
                             {/* <img src={RightArrow} alt='User' className='search-icon' width="100%" height="auto" /> */}
                          
                            </div>
                            <div className='testi-user-name'>
                                <h3>{testimonial.name}</h3>
                                <h3 className='post-name'>{testimonial.desginastion}</h3>
                                <div className='testi-content'>
                                    <p>{testimonial.content}</p>
                                </div>

                      
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            </div>
        </div>
    );
}
