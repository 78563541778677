import React from "react";
import getStartedimg from "../../../assets/get-started-01.png";
import BestLubricant from "../../../assets/best-lubricant.jpg";
import getstarted from "../../../assets/get-started-02.png";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";

export default function GetStarted() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="reliable-services-mnc">
        <div className="container">
          <div className="row reliable-services">
            <div className="col-xl-12 col-md-12 col-sm-12">
              <img src={getStartedimg} alt="Product" className="img-fluid" />
            </div>
            <div className="col-xl-12 col-md-12 col-sm-12 ">
              <div className="get-services-right">
                <p className="text-clear-service">
                  Eco Fleet Services is your trusted Fuel and Lubricant
                  supplier. Our reputation in ensuring your vehicles are up and
                  running with the highest performance is unrivaled in the
                  region. Over the years we have refined our sales and delivery
                  processes and streamlined them to meet the needs of our
                  fast-growing list of clients. Partnering with us will ensure
                  you have signed up for a resourceful and dependable partner
                  that prioritizes your needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="reliable-products-home">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-md-12 col-sm-12 pro-service-left">
              <h3 className="title-progress">
                Among the products available for this program include
              </h3>
              <div class="box-pro-icon-cl">
                <div class="check-bx-iconc">
                  <h3 class="title-ic">
                    <FaCheck />
                    Vehicle fluids{" "}
                  </h3>
                  <h3 class="title-ic">
                    <FaCheck />
                    Lubricants (DEF)
                  </h3>
                  <h3 class="title-ic">
                    <FaCheck />
                    Coolant and Antifreeze Fluids
                  </h3>
                </div>
                <div class="check-bx-icon">
                  <h3 class="title-ic">
                    <FaCheck />
                    Vehicle performance
                  </h3>
                  <h3 class="title-ic">
                    <FaCheck />
                    Cost Savings
                  </h3>
                </div>
              </div>
              <div class="bottom-content">
                <p className="text-pro-cl">
                  Join a group of satisfied customers by getting in touch with
                  experts for advice on how to manage your fleet and which of
                  the products we offer will be perfect for your vehicles. Our
                  team will provide answers to any of your questions about the
                  following subjects{" "}
                </p>
                <p class="text-pro-cl">
                  Eco Fleet Services is your trusted Fuel and Lubricant
                  supplier. Our reputation in ensuring your vehicles are up and
                  running with the highest performance is unrivaled in the
                  region. Over the years we have refined our sales and delivery
                  processes and streamlined them to meet the needs of our
                  fast-growing list of clients. Partnering with us will ensure
                  you have signed up for a resourceful and dependable partner
                  that prioritizes your needs
                </p>
              </div>
            </div>
            <div class="col-xl-6 col-md-12 col-sm-12 product-service-right">
              <img src={getstarted} alt="Product" className="img-fluid" />
            </div>
          </div>
          <div className="col-xl-12 col-md- col-sm-12 We-offer-main-left">
            <div className="bottom-content-started">
              <p className="reliable-content">
                We provide emergency delivery services within the region with
                quick turnaround times. If you require DEF, Antifreeze Fluids,
                and Lubricants for your vehicles, then we are your one-stop
                partner. Once you get in touch you don’t have to worry about the
                performance of your vehicles. All you have to do is focus your
                energy on running your business because we are here to make sure
                there are no downtimes or unpleasant surprises.
              </p>

              <p className="reliable-content">
                Join us now and let us provide you with the best vehicle fluid
                and lubricant products in the market.
              </p>
              <div className="clear-choice-btn">
                <button
                  onClick={() => {
                    navigate(`/Contact`);
                  }}
                  className="bulk-cl"
                >
                  Contact Us Today
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
