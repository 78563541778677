import React from "react";
import Header from "../CommonComponents/Header";
import ReliableService from "../../../assets/aboutUS1.jpg";


export default function About() {
  return (
    <>
      {/* <h1>UltraClear page</h1> */}

      <div className="eco-services-mnc">
        <div className="container">
          <div className="row reliable-services">
            <div className="col-xl-6 col-md-12 col-sm-12">
              <img src={ReliableService}></img>
            </div>
            <div className="col-xl-6 col-md-12 col-sm-12 reliable-services-right">
              <h2 className="eco-title">Eco Fleet Services</h2>
              <p className="text-clear-about">
                Eco Fleet Services is your trusted provider of vehicle fluids
                and lubricants. For years we have been on the frontline to
                ensure vehicles running in North America have a constant and
                reliable supply of high-quality products to enhance performance
                and operate efficiently. With our exemplary products and the
                help of our seasoned vehicle fluid experts, our sole aim is to
                ensure we are the number one supplier of Vehicle Fluids and
                Lubricants in North America and the surrounding regions.
              </p>
              {/* <p className="text-clear">Eco fleet services<strong>bulk refill</strong>  program was introduced to ensure clients that needed 
                  large volumes of DEF or other Fluids are directly delivered to their locations saving them a lot 
                  of time and the logistics needed to transport these products.</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="map-main">
      <div classname="col-lg-12">
        <div className="container">
          <div className="map-height">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2882.2840372634523!2d-79.79450552396341!3d43.746198146411345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b1755bb5716e7%3A0x47ba2da2872ab6b2!2s25%20Newkirk%20Ct%20a3%2C%20Brampton%2C%20ON%20L6Z%200B5%2C%20Canada!5e0!3m2!1sen!2sin!4v1722513717048!5m2!1sen!2sin"
          width="100%"
          height="500px"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
      </div>
      </div>
      </div>
    </>
  );
}
