import React from "react";
import "./pages.css";
import Pagination from "@mui/material/Pagination";
import banner1 from "../../../assets/Homepage.jpg";
import banner2 from "../../../assets/Bulk -def.jpg";
import banner3 from "../../../assets/Fuel-lubricants.jpg";
import banner4 from "../../../assets/Vehicle-fluid.jpg";
import banner5 from "../../../assets/Exhaust-Fluid.jpg";
import HomeBanner from "../../../assets/banner-one.png";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
export default function HeaderBanner() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const toggleMenu =()=>{
    navigate(`/get-started`)
  }

  // const banners = [
  //     {
  //         title: t('Vehicle fluids and lubricants in North America'),
  //         description: t('Lorem Ipsum is simply dummy text of the printing'),
  //         image: banner1
  //     },
  //     {
  //         title: t('Bulk DEF'),
  //         description: t('Some other description goes here'),
  //         image: banner2
  //     },
  //     {
  //         title: t('Fuel and lubricants'),
  //         description: t('More dummy text for the third banner'),
  //         image: banner3
  //     },

  //     {
  //         title: t('Vehicle fluid'),
  //         description: t('More dummy text for the third banner'),
  //         image: banner4
  //     },
  //     {
  //         title: t('Diesel Exhaust Fluid'),
  //         description: t('More dummy text for the third banner'),
  //         image: banner5
  //     },

  // ];

  return (
    <>
      {<div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={HomeBanner} className="d-block w-100" alt="..." style={{height:"760px",width:"100%"}} />
            <div className="carousel-caption d-none d-md-block">
            <div className="banner-content"><h5 className="home-banner-title">Welcome to Eco Fleet Services</h5>
              <p className="home-banner-content">
                trusted provider of vehicle fluids and lubricants in North America</p>
              <button   onClick={toggleMenu} className="btn btn-primary get-started-vc">Get Started</button>
              </div> 
            </div>
          </div>
        </div>
      </div> }
    </>
  );
}
