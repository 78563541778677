import React from 'react'
import HeaderBanner from '../CustomerDashboard/Components/CommonPages/HeaderBanner'
import ProductDetails from '../CustomerDashboard/Components/CommonPages/ProductDetails'
import AdvantageBanner from '../CustomerDashboard/Components/CommonPages/AdvantageBanner'
import BetterService from '../CustomerDashboard/Components/CommonPages/BetterService'
import LatestProduct from '../CustomerDashboard/Components/CommonComponents/LatestProduct'
import Testimonials from '../CustomerDashboard/Components/CommonPages/Testimonials'
import ClearChoice from '../CustomerDashboard/Components/CommonPages/ClearChoice'
export default function HomePage() {
    return (
        <div>
            <HeaderBanner />
            <ClearChoice/>
            <ProductDetails />
            <AdvantageBanner />
            <BetterService />
            <LatestProduct />
            <Testimonials />
        </div>
    )
}
