import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import profileIcon from "../../assets/img/profile-ic.png";
import { FiLogOut } from "react-icons/fi";
import CustomerProfile from "./CustomerProfile.jsx";

export default function SideBar () {

  const Navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userToken = localStorage.getItem("userToken");
  const userId = localStorage.getItem("userId");

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("customerId");
    Navigate("/");
    setIsDropdownOpen(false);
  };

  return (
    <>
    <CustomerProfile />
      <section className="home">
        <div className="main-header-tag-title">
          <div className="Side-bar-center">Customer Dashboard</div>
          <div className="profile-icon">
            <img
              src={profileIcon}
              className="person-image"
              alt="profile-icon"
              onClick={toggleDropdown}
              style={{ cursor: "pointer" }}
            />
            {isDropdownOpen && userToken && (
              <div className="dropdown-menu">
                <button className="logout-button" onClick={handleLogout}>
                  <FiLogOut />
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
      </>
  )
}
