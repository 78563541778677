import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ProductTwo from "../../../assets/products_2.jpg";
import Products from "../../../assets/product-ban.jpg";
import SearchProduct from "../../APIComponents/SearchAPI/SearchProduct";
import { FaCheck } from "react-icons/fa6";
import {
  GetProductAPI,
  LatestProductAPI,
} from "../../APIComponents/ProductAPIs/GetAPI";
import { Toaster, toast } from "react-hot-toast";
import { AddToCart, getCustomerId } from "../../APIComponents/CartsApi";
import { useCart } from "../../PopupComponent/CartContext";
import CartTabPopup from "../../PopupComponent/CartTabPopup";

export default function ShowAllProduct() {
  const { updateCartCount } = useCart();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [page, setPage] = useState(1);
  const productsPerPage = 4;
  const [showLatest, setShowLatest] = useState(false);
  const [latestProducts, setLatestProducts] = useState([]);
  const [isCartPopupOpen, setCartPopupOpen] = useState(false);
  const [cartItem, setCartItem] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  useEffect(() => {
    const initializeIdentifier = async () => {
      try {
        const storedUserId = localStorage.getItem("userId");
        const storedCustomerId = localStorage.getItem("customerId");
        const id = storedUserId || storedCustomerId;

        if (id) {
          setCustomerId(id);
        } else {
          const customerIdFromApi = await getCustomerId();
          localStorage.setItem("customerId", customerIdFromApi);
          setCustomerId(customerIdFromApi);
        }
      } catch (error) {
        console.error("Error initializing customer ID:", error);
      }
    };
    initializeIdentifier();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!customerId) {
        toast.error("Customer id not found!");
        return;
      }
      const searchParams = new URLSearchParams(location.search);
      const latestParam = searchParams.get("latest");
      const queryParam = searchParams.get("query");

      if (latestParam === "true") {
        setShowLatest(true);
        await getLatestProduct();
      } else {
        setShowLatest(false);
        await getAllProduct();
      }

      if (queryParam) {
        setQuery(queryParam);
      } else {
        setQuery("");
      }
    };
    if (customerId) {
      fetchData();
    }
  }, [customerId, location.search]);

  const getAllProduct = async () => {
    try {
      const storedUserId = localStorage.getItem("userId");
      const storedCustomerId = localStorage.getItem("customerId");
      const id = storedUserId || storedCustomerId;
      const data = await GetProductAPI(id);
       console.log(data , "data")
       if (data) {
        const filteredProducts = data.filter((product) => {
          const stock = typeof product.productStock === 'number' 
            ? product.productStock 
            : product.productStock === true 
              ? 1 
              : 0;
          return product.productActive && stock > 0;
        });
      
        setAllProducts(filteredProducts);
      }
      
    } catch (error) {
      console.log(error.message);
    }
  };

  const getLatestProduct = async () => {
    try {
      const storedUserId = localStorage.getItem("userId");
      const storedCustomerId = localStorage.getItem("customerId");
      const id = storedUserId || storedCustomerId;
      const data = await LatestProductAPI(id);
      if (!data || !data.data.products) {
        toast.error("No latest product!");
        setLatestProducts([]);
      } else {
        const filteredProducts = data.filter((product) => {
          const stock = typeof product.productStock === 'number' 
            ? product.productStock 
            : product.productStock === true 
              ? 1 
              : 0;
          return product.productActive && stock > 0;
        });
        setLatestProducts(filteredProducts);
      }
    } catch (error) {
      console.log("Error fetching latest products:", error.message);
      setLatestProducts([]);
    }
  };

  const handleAddToCart = async (productId) => {
    try {
      const response = await AddToCart(customerId, productId, 1);

      if (response && response.status === 200) {
        toast.success(response.message);
        updateCartCount();
        setCartItem(productId);
        setCartPopupOpen(true);
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error("Error adding product to cart");
    }
  };

  const viewProduct = (productId) => {
    navigate(`/viewSingleProduct/${productId}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const totalPages = Math.ceil(
    showLatest
      ? latestProducts.length / productsPerPage
      : query?.length === 0
        ? allProducts.length / productsPerPage
        : results.length / productsPerPage
  );


  const shouldShowPagination = showLatest
    ? latestProducts.length > productsPerPage
    : query?.length === 0
      ? allProducts.length > productsPerPage
      : results.length > productsPerPage;


  const currentProducts = showLatest
    ? latestProducts.slice(
      (page - 1) * productsPerPage,
      page * productsPerPage
    )
    : query?.length === 0
      ? allProducts.slice((page - 1) * productsPerPage, page * productsPerPage)
      : results.slice((page - 1) * productsPerPage, page * productsPerPage);

  useEffect(() => {
    const fetchSearchResults = async () => {
      const searchParams = new URLSearchParams(location.search);
      const queryParam = searchParams.get("query");
      console.log("queryparam>>>>",queryParam);

      if (!queryParam || queryParam.trim() === "") {
        setShowLatest(false);
        getAllProduct();
        setResults([]);
      } else {
        try {
          const response = await SearchProduct(queryParam);
          const responseData = response.data;
          if (responseData.status === 200) {
            if (responseData.product) {
              setResults(responseData.product);
            } else {
              setResults([{ message: responseData.product }]);
            }
          } else {
            toast.error(responseData.product);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setResults([{ message: "No Product Found" }]);
        }
      }
    };

    fetchSearchResults();
  }, [location.search]);

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="product-banner-main">
        <img src={Products} alt="Product" className="img-fluid" />
      </div>

      <div className="reliable-products-home">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12 col-sm-12 pro-service-left1">
              <h3 className="title-progress">
                Performance. Our products include
              </h3>
              <div className="box-pro-icon-cl">
                <div className="check-bx-iconc">
                  <h3 className="title-ic">
                    <FaCheck />
                    Diesel Exhaust Fluid{" "}
                  </h3>
                  <h3 className="title-ic">
                    <FaCheck />
                    Lubricants including Engine Oil(DEF)
                  </h3>
                </div>
                <div className="check-bx-icon">
                  <h3 className="title-ic">
                    <FaCheck />
                    Antifreeze
                  </h3>
                  <h3 className="title-ic">
                    <FaCheck />
                    Washer fluids
                  </h3>
                </div>
              </div>
              <div className="bottom-content">
                <p className="text-pro-cl">
                  We offer a wide range of OEM approved products that ensure
                  your vehicles have access to quality products in a timely
                  fashion to ensure efficiency, reduced costs, and top-notch{" "}
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-md-12 col-sm-12 product-service-right">
              <img src={ProductTwo} alt="Product" className="img-fluid" />
            </div>
            <div className="product-btm-content">
              <p className="reliable-content">
                <strong> Diesel Exhaust Fluid (DEF):</strong> Our DEF helps
                reduce emissions and improve the efficiency of diesel engines.
                It is a high-purity urea solution that meets stringent industry
                standards, ensuring optimal performance and compliance with
                environmental regulations.
              </p>
              <p className="reliable-content">
                <strong>Antifreeze:</strong> Our antifreeze products protect
                your engine from freezing in cold temperatures and overheating
                in hot conditions. They are formulated to prevent corrosion and
                ensure the longevity of your engine components.
              </p>
              <p className="reliable-content">
                <strong>Engine Oil: </strong> We provide high-quality engine
                oils that are engineered to enhance the performance and
                longevity of your vehicle's engine. Our engine oils are
                available in various grades to suit different engine types and
                conditions, ensuring your engine runs smoothly and efficiently
                under all driving conditions.
              </p>
              <p className="reliable-content">
                <strong>Break Cleaners:</strong> Our break cleaners are
                formulated to remove grease, oil, and other contaminants from
                your vehicle's brake components, ensuring optimal performance
                and safety. They are designed to work quickly and effectively,
                leaving no residue and improving the lifespan of your braking
                system.
              </p>
              <p className="reliable-content">
                <strong>Environmental Friendliness:</strong> All our products
                are environmentally friendly and designed to minimize the
                ecological impact. Our lubricants and fluids are crafted to
                reduce emissions and improve fuel efficiency, supporting your
                commitment to sustainability.
              </p>
              <p className="reliable-content">
                We have partnered with Lubricants manufacturers who are OEM
                approved and producing vehicle fluids and lubricants that can
                withstand extreme conditions while ensuring your vehicles
                operate at peak performance.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="product-main-cl">
        <div className="container">
          <h2 className="product-title-main">Related Products</h2>
          <div className="row">
            {showLatest
              ? latestProducts.length > 0 ? (
                currentProducts.map((product) => (
                  <div className="col-md-3 mb-4" key={product._id}>
                    <div className="card">
                      <div className="zoom-effect">
                        <div className="card-img-container">
                          <img
                            src={product.productImage}
                            className="card-img-top"
                            alt={product.productTitle}
                            onClick={() => viewProduct(product._id)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">{product.productTitle}</h5>

                        <p className="price-product">
                          Price: ${product.productPrice}
                        </p>

                        <button
                          className="btn btn-primary add-cart"
                          onClick={() => handleAddToCart(product._id)}
                          disabled={product.productStockAvailable <= 0}
                        >
                          {product.productStockAvailable <= 0
                            ? t("Out of Stock")
                            : t("Add to Cart")}
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-12">
                  <div className="alert alert-info" role="alert">
                    No latest products available.
                  </div>
                </div>
              )
              : (query?.length === 0 ? currentProducts : results)?.map(
                (product, index) => (
                  <div className="col-md-3 mb-4" key={index}>
                    {product.message ? (
                      <div className="card">
                        <div className="card-body">
                          <p>{product.message}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="card">
                        <div className="zoom-effect">
                          <div className="card-img-container">
                            <img
                              src={product.productImage}
                              className="card-img-top"
                              alt={product.productTitle}
                              onClick={() => viewProduct(product._id)}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>
                        <div className="card-body">
                          <h5 className="card-title">
                            {product.productTitle}
                          </h5>

                          <p className="price-product">
                            Price: ${product.productPrice}
                          </p>

                          <button
                            className="btn btn-primary add-cart"
                            onClick={() => handleAddToCart(product._id)}
                            disabled={product.productStockAvailable <= 0}
                          >
                            {product.productStockAvailable <= 0
                              ? t("Out of Stock")
                              : t("Add to Cart")}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}
          </div>

          {shouldShowPagination && (
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              color="primary"
              style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
            />
          )}
        </div>
      </div>
      {isCartPopupOpen && (
        <CartTabPopup
          open={isCartPopupOpen}
          onClose={() => setCartPopupOpen(false)}
        />
      )}
    </>
  );
}
