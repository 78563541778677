import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import banner from "../../../../assets/b1.jpg";
import {
  getCart,
  getCustomerId,
  UpdateCart,
  DeleteCart,
} from "../../../APIComponents/CartsApi";
import { HiMinus } from "react-icons/hi2";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import "./common.css";
import { useCart } from "../../../PopupComponent/CartContext";

export default function ViewCart() {
  const [products, setProducts] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { updateCartCount } = useCart();

  useEffect(() => {
    const fetchProducts = async () => {
      const userId = localStorage.getItem('userId');
      const customerId = userId ? userId : getCustomerId();
      try {
        const response = await getCart(customerId);
        const fetchedProducts = response.data || [];
        setProducts(fetchedProducts);
        const calculatedSubtotal = fetchedProducts.reduce((acc, item) => {
          const { productDetails, Quantity } = item;
          const priceStr = productDetails?.productPrice || "$0.00";
          const price =
            parseFloat(priceStr.replace("$", "").replace(/[^0-9.-]+/g, "")) ||
            0;
          const quantity = parseInt(Quantity, 10) || 0;
          return acc + price * quantity;
        }, 0);

        setSubtotal(calculatedSubtotal);
      } catch (error) {
        console.error("Error fetching cart items:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  const updateQuantity = async (productId, newQuantity) => {
    const userId = localStorage.getItem('userId');
    const customerId = userId ? userId : getCustomerId();
    try {
      const response = await UpdateCart(customerId, productId, newQuantity);
      if (response && response.status === 200) {
        const updatedProducts = products.map((item) =>
          item?.productDetails._id === productId
            ? { ...item, Quantity: newQuantity }
            : item
        );
        setProducts(updatedProducts);
        calculateSubtotal(updatedProducts);
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
      toast.error("Failed to update product quantity");
    }
  };

  const handleMinusQuantity = (productId, currentQuantity) => {
    if (currentQuantity <= 1) return;
    const newQuantity = currentQuantity - 1;
    updateQuantity(productId, newQuantity);
  };

  const handlePlusQuantity = (productId, currentQuantity,productStockAvailable) => {
    const newQuantity = currentQuantity + 1;
    console.log("newQuantity>>>",newQuantity);
    console.log("productStockAvailable",productStockAvailable);
    if(newQuantity<=productStockAvailable)
    {
    updateQuantity(productId, newQuantity);
    }
  };
  const handleRemoveProduct = async (productId) => {
    const userId = localStorage.getItem('userId');
    const customerId = userId ? userId : getCustomerId();
    try {
      const response = await DeleteCart(customerId, productId);
      if (response && response.status === 200) {
        const updatedProducts = products.filter(
          (item) => item?.productDetails._id !== productId
        );
        setProducts(updatedProducts);
        calculateSubtotal(updatedProducts);
        toast.success("Product removed from cart");
        updateCartCount();
      }
    } catch (error) {
      console.error("Error removing product from cart:", error);
      toast.error("Failed to remove product from cart");
    }
  };

  const calculateSubtotal = (updatedProducts) => {
    const calculatedSubtotal = updatedProducts.reduce((acc, item) => {
      const { productDetails, Quantity } = item;
      const priceStr = productDetails?.productPrice || "$0.00";
      const price =
        parseFloat(priceStr.replace("$", "").replace(/[^0-9.-]+/g, "")) || 0;
      const quantity = parseInt(Quantity, 10) || 0;
      return acc + price * quantity;
    }, 0);
    setSubtotal(calculatedSubtotal);
  };
  const viewProduct = async (productId) => {
    try {
      navigate(`/viewSingleProduct/${productId}`);
    } catch (error) {
      console.error("Error closing popup or navigating:", error);
    }
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      <div className="shopping-cart-top">
        <div className="container">
          <div className="view-cart-heading ">
            <p className="view-cart-title fs-3">Your Shopping Cart</p>
            <p className="view-cart-linking">
              <Link to="/">Home</Link>&nbsp;/&nbsp;
              <span>Your Shopping Cart</span>
            </p>
          </div>
        </div>
      </div>

      <div className="order-sep-cart cart_page_outer">
        <div className="container">
          <div className="view-cart-details">
            <div className="d-flex justify-content-between">
              <div className="left-cart-tab">
                <table className="table">
                  <thead>
                    <tr className="table-cart">
                      <th scope="col">Product</th>
                      <th scope="col">Price</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>

                  <tbody className="checkout-product-ct">
                    {products.map((item) => {
                      const { productDetails, Quantity } = item;

                      if (!productDetails) {
                        return (
                          <>
                          </>
                        )
                      }


                      const priceStr = productDetails?.productPrice || "$0.00";
                      const productPrice =
                        parseFloat(
                          priceStr.replace("$", "").replace(/[^0-9.-]+/g, "")
                        ) || 0;
                      const quantity = parseInt(Quantity, 10) || 0;
                      const total = (productPrice * quantity).toFixed(2);

                      return (
                        <tr key={item?.productDetails._id}>
                          <td>
                            <div className="pro_table_des">
                              <div className="pro_table_img">
                                <img
                                  src={productDetails?.productImage || banner}
                                  alt="Product"
                                  height="auto"
                                  width="15%"
                                  onClick={() => viewProduct(item?.productDetails._id)}
                                /></div>
                              <span>{productDetails?.productTitle}</span></div>
                          </td>
                          <td>{priceStr}</td>
                          <td>
                            <div className="pro-quant d-flex">
                              <div className="pro-buttons border">
                                <button
                                  className="btn"
                                  onClick={() =>
                                    handleMinusQuantity(
                                      productDetails._id,
                                      quantity
                                    )
                                  }
                                >
                                  <HiMinus />
                                </button>
                                <input
                                  type="number"
                                  className="quantity-input"
                                  value={quantity}
                                  readOnly
                                />
                                <button
                                  className="btn add_qty"
                                  onClick={() =>
                                    handlePlusQuantity(
                                      productDetails._id,
                                      quantity,
                                      item?.productDetails
                                      ?.productStockAvailable
                                    )
                                  }
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>
                          </td>
                          <td>${total}</td>
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={() =>
                                handleRemoveProduct(productDetails._id)
                              }
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* <div className="order-details">
                <p className="order-instruction-title">
                  ORDER SPECIAL INSTRUCTIONS
                </p>
                <div className="order-instruction-body border">
                  <span className="instr-body-title">
                    Order special instructions
                  </span>
                </div>
              </div> */}
            </div>
            <div className="d-flex justify-content-end view-cart-footer">
              <div className="view-cart-content border text-center">
                <div className="cart_view">
                  <h3 className="cart-foot-heading">
                    Subtotal: ${subtotal.toFixed(2)}
                  </h3>
                  <p>Taxes and shipping calculated at checkout</p>
                </div>
                <div className="cart_checkout">
                  {products.length === 0 ? (
                    <button className="btn btn-primary py-2 px-5 fs-5" disabled>
                      Check Out
                    </button>
                  ) : (
                    <Link to="/Checkout">
                      <button className="btn btn-primary py-2 px-5 fs-5">
                        Check Out
                      </button>
                    </Link>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
