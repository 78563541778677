import React, { useEffect, useState } from "react";
import CustomerProfile from "../customerDashboardProfile/CustomerProfile";
import { RecentOrderapi } from "../customerDashboardApi/PostApi";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Sidebar from "../customerDashboardProfile/SideBar";
import "./RecentOrder.css";
import {toast } from "react-hot-toast";

export default function RecentOrder() {
  const [recentOrder, setRecentOrder] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(3);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userToken = localStorage.getItem("userToken");
  const Navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userId");
    Navigate("/");
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const fetchRecentOrder = async () => {
      try {
        const response = await RecentOrderapi();
        if (response.status === 403 || response.message === "Invalid token") {
          toast.error("Unauthorized access. Please log in again.");
          localStorage.removeItem("refreshToken");
          setTimeout(()=>{
           Navigate("/customerLogin")
          },3000)
          
        } 
        else{
        setRecentOrder(response.orders || []);
        }
      } catch (error) {
        console.log(error, "error");
      }
    };

    fetchRecentOrder();
  }, []);

  const pageCount = Math.ceil(recentOrder.length / itemsPerPage);

  const handlePageChange = (event) => {
    setCurrentPage(event.selected);
  };

  const displayedOrders = recentOrder.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  return (
    
    <>
      <Sidebar/>
      {displayedOrders.length>0? <>
      <table
        className="table table-striped"
        style={{ width: "70%", marginLeft: "20%", marginTop: "10%" }}
      >
        <thead>
          <tr>
            <th scope="col">Product Image</th>
            <th scope="col">Title</th>
            <th scope="col">Status</th>
            <th scope="col">Product ID</th>
            <th scope="col">Product Price</th>
          </tr>
        </thead>
        <tbody>
          {displayedOrders.map((order) =>
            order.productDetails.length > 0 ? (
              <tr key={order._id}>
                <td>
                  <Link to={`/admin/recentOrderDetail?orderId=${order._id}`}>
                    {order.productDetails[0].productInfo.productImage && (
                      <img
                        src={order.productDetails[0].productInfo.productImage}
                        alt={order.productDetails[0].productInfo.productTitle}
                        style={{ height: "100px", width: "50%" }}
                        className="order-img"
                      />
                    )}
                  </Link>
                </td>
                <td>{order.productDetails[0].productInfo.productTitle}</td>
                <td>{order.deliveryStatus}</td>
                <td>{order.productDetails[0].orderedProductId}</td>
                <td>${order.productDetails[0].productInfo.productPrice}</td>
              </tr>
            ) : null
          )}
        </tbody>
      </table>
    <div style={{ marginLeft: "30%" }}>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />
    </div>
   </>:
    <div style={{ marginLeft: "20%", marginTop: "10%" }}>
    <p>No recent orders found.</p>
  </div>
}
</>
  );
}
