import React from "react";
import BestService from "../../../assets/whyus.jpg";
import HdExpert from "../../../assets/HDExpert.jpg";
import ShellLogo from "../../../assets/shell_logo.jpg";
import MobDevlac from "../../../assets/mobile_devlac.png";
export default function WhyUltraClear() {
  return (
    <div>
      <div class="reliable-services-mnc">
        <div class="container">
          <div class="row reliable-services">
            <div class="col-xl-12 col-md-12 col-sm-12">
              <img src={BestService} alt="Product" className="img-fluid" />
            </div>
            <div class="col-xl-12 col-md-12 col-sm-12 ">
              <div class="why-us-content">
              <h2 className="why-us-tile">our Trusted Partner for Peak Vehicle Performance and Affordable Solutions</h2>
                <p class="text-clear-service1">
                  Eco Fleet Services is committed to ensuring all vehicle needs
                  for both small and large enterprises are met. Our services are
                  designed with everyone's needs in mind, ensuring anyone
                  looking to open their fleet business to excellent performance
                  is well taken care of. Not only do the products we provide
                  guarantee peak performance of your vehicles but they are also
                  available at the most affordable rates in the region.
                </p>
                <p class="text-clear-service1">
                  Partnering with us will guarantee you excellent performance,
                  exclusive delivery services, as well as expert advice on what
                  works for your fleet. From the Affordable Lubricants,
                  high-quality DEF, and carefully crafted packaged and delivery
                  solutions that meet the needs of everyone, we are confident to
                  say that we are the best vehicle fluid suppliers around.
                </p>
                <p class="text-clear-service1">
                  Our experts ensure our product catalog is always updated with
                  the best products in the market by closely working with
                  partner manufacturers and continuously carrying out research
                  to keep tabs on emerging vehicle trends in the market to
                  guarantee customer satisfaction for both existing and new
                  clients.
                </p>
                <p class="text-clear-service1">
                  We at Eco Fleet Services will always put our customers first
                  as we understand clearly what is needed for every fleet
                  service. If you are looking for the best solutions for your
                  vehicles, we have a long list of solutions to choose from to
                  ensure we meet your specific needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="major-brand-main">
        <div className="container">
          <div className="major-brand-box">
            <div className="row trust-section">
              <div className="col-xxl-12 col-xl-12 col-md-12 col-sm-12 ">
            <h2 className="brand-title-we">
              We work with major Brands you can trust
            </h2>
            <div className="brand-logo">
            <img src={HdExpert} alt="Product" className="img-fluid" />
            <img src={ShellLogo} alt="Product" className="img-shell" />
            <img src={MobDevlac} alt="Product" className="img-fluid" />
          </div>
            </div>
          <div className=" col-xxl-6 col-xl-6 col-md-12 col-sm-12 ">
   
          </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
