import axios from "axios";
import { v4 as uuidv4 } from "uuid";  // Use uuid for generating unique IDs

const url = process.env.REACT_APP_BASE_URL;

const headers = {
  'Content-Type': "application/json"
}

// ........................Add to Cart..............................
export const AddToCart = async (customerId, productId, quantity) => {
  try {
    const response = await axios.post(
      `${url}/cart`,
      {
        customerId,
        productId,
        quantity,
      },
      { headers }
    );

    return response.data;
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};

// ...................Update Cart ................................
export const UpdateCart = async (CustomerId, productId, quantity) => {
  try {
    const response = await axios({
      method: "put",
      url: `${url}/update-cart/${CustomerId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        productId,
        quantity,
      },
    });

    return response;
  } catch (error) {
    console.error(error.message, "error");
    throw error;
  }
};

// ..................delete cart...........................
export const DeleteCart = async (customerId, productId) => {
  try {
    const response = await axios(
      {
        method: "delete",
        url: `${url}/delete-cart`,
        data: { customerId, productId },
      },
      {
        headers: headers,
      }
    );

    return response;
  } catch (e) {
    console.log(e.message, "errror");
  }
};

export const getCustomerId = () => {
  const userId = localStorage.getItem("userId");
  let customerId = localStorage.getItem("customerId");

  if (!customerId) {
    if (!userId) {
      // Generate a unique ID using uuid
      customerId = uuidv4();
    } else {
      customerId = userId;
    }
    localStorage.setItem("customerId", customerId);
  } else {
    console.log("Found existing customerId:", customerId);
  }
  return customerId;
};

// ...................Get Cart Details...................
export const getCart = async (customerId) => {
  try {
    const response = await axios.get(`${url}/cartDetail/${customerId}`, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.log(error, "error");
    return { data: [] };
  }
};
