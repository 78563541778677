import React from "react";
import "./Thankyou.css"
import { Link } from "react-router-dom";
export default function Thankyou() {
  return (
    <div className="thanks_page">
      <div className="content">
        <div className="wrapper-1">
          <div className="wrapper-2">
            <h1>Thank You for Your Order!</h1>
          <p>Your order has been successfully placed.</p>
          <p>We appreciate your business and hope you enjoy your purchase!</p>
           <Link to="/" ><button className="go-home">go home</button></Link>
          </div>
        </div>
      </div>
    </div>
  );
}
