import React, { useState } from "react";
import axios from "axios";
import ContactBanner from "../../../assets/contact-banner.jpg";
import "../../Components/CommonComponents/contact.css";
import ConatctUs from "../../../assets/contact-us-img.jpg";
import { ContactFormApi } from "../../APIComponents/ProductAPIs/GetAPI";
import { toast } from "react-toastify";
export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  // .............handle change function...............
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  // ...............handle submit function............
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await ContactFormApi(formData);
      console.log("Response from server:", response.data);
      toast.success(response.data.message);
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("something wrong Try Again!");
    }
  };

  return (
    <div>
      <div className="contact-banner">
      <img src={ConatctUs}></img>
     
        <div className="container">
          {/* <div className="contact-banner-img">
          <h1 className="contact-main-title-banner">Contact Us</h1>
          </div> */}
        </div>
      </div>

      <div className="contact-us-page">
        <div className="container">
          <div className="row flex-contact">
            <div className="col-xl-6 contact-left">
              <h2 className="contact-right-title">
              Have questions or need assistance?
              </h2>
              <p className="contact-content-right">
              Reach out to us today, and our team will be happy to help!
              </p>
              <div className="map-contact">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2882.2840372634523!2d-79.79450552396341!3d43.746198146411345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b1755bb5716e7%3A0x47ba2da2872ab6b2!2s25%20Newkirk%20Ct%20a3%2C%20Brampton%2C%20ON%20L6Z%200B5%2C%20Canada!5e0!3m2!1sen!2sin!4v1722513717048!5m2!1sen!2sin"
                width="100%"
                height="300px"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
      </div>

              <div className="icon-with-text">
                <div className="d-flex">
                  <div className="icon">
                    <i className="fas fa-location-arrow"></i>
                  </div>
                  <div className="text">
                  <a href="https://maps.app.goo.gl/KyJk9ywPkVVN7rtQ8">A3, 25 Newkirk Ct, Brampton, ON L6Z0J8, Canada </a>
                  </div>
                </div>
              </div>

              <div className="icon-with-text">
                <div className="d-flex">
                  <div className="icon">
                    <i className="fas fa-envelope"></i>
                  </div>
                  <div className="text"><a href="mailto:info@ecofleetservices.ca">info@ecofleetservices.ca</a></div>
                </div>
              </div>

              <div className="icon-with-text">
                <div className="d-flex">
                  <div className="icon">
                    <i className="fas fa-phone-alt"></i>
                  </div>
                  <div className="text"><a href="tel:647-615-0008">647-615-0008</a></div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 contact-right">
              <h2 className="contact-left-title">Get In Touch</h2>
              <form
                onSubmit={handleSubmit}
                className="w-100 rounded-1 p-4 border bg-white contact-us-page-right"
              >
                <label className="d-block mb-4">
                  <span className="form-label d-block">Your name</span>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Joe Bloggs"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </label>

                <label className="d-block mb-4">
                  <span className="form-label d-block">Email address</span>
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="joe.bloggs@example.com"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </label>

                <label className="d-block mb-4">
                  <span className="form-label d-block">Message</span>
                  <textarea
                    name="message"
                    className="form-control"
                    rows="3"
                    placeholder="Tell us what you're thinking about..."
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </label>

                <div className="mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary px-3 rounded-3"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
