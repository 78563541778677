import React from 'react';
import { useTranslation } from 'react-i18next';
import ValueIcon from '../../../assets/quality icon.png'
import ValueIcon1 from '../../../assets/value icon.png'
import ValueIcon2 from '../../../assets/sustainability icon.png';   

export default function AdvantageBanner() {
    const { t, i18n } = useTranslation();


    const advantageData = [
        {
            id: 1,
            title: t('QUALITY'),
            icon: ValueIcon,
            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries'
        },
        {
            id: 2,
            title: t('VALUE'),
            icon: ValueIcon1,
            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries'
        },
        {
            id: 3,
            title: t('SUSTAINABILITY'),
            icon: ValueIcon2,
            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries'
        }
    ];

    return (
        <div className='advantage-area-main'>
        <div className='container'>
            <div className='advantage-area'>
                <div className='advantage-header'>
                    <h2 className='heading-main'>
                        {t("The Clear Advantage")}
                    </h2>
                    <p className='advantage-body'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                </div>
                <div className='row advantages'>
                    {advantageData.map(item => (
                        <div key={item.id} className='col-md-4 box-advantage'>
                            <div className='box-heading'>
                                <h2>{item.title}</h2>
                                {/* <span>{item.icon}</span> */}
                                <span className='image-bx'> <img src={item.icon}></img></span>
                            </div>
                            <div className='box-body'>
                                {item.content}
                            </div>
                        </div>
                    ))}
                </div>
                 
            </div>
            </div>
        </div>
    );
}
