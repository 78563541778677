import React from 'react'
import banner1 from '../../../assets/b1.jpg'
import { useTranslation } from 'react-i18next';

export default function BetterService() {
    const { t, i18n } = useTranslation();

    return (
        <>
        <div className='better-service-main'>            <div className='container'>
                <div className='main-service-area row'>
                    <div className='col-md-6'>
                        <div className='service-image'>
                            <img src={banner1} alt='logo.png' />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='service-content'>
                            <h2 className='service-heading'>{t("Clearly Better Service")}</h2>
                            <p className='service-body'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.</p>
                        </div>
                        <div className='service-buttons'>   
                            <button type='button' className='btn btn-primary'>{t("NEW CUSTOMERS")}</button>
                            <button type='button' className='btn btn-primary mt-2'>{t('EXSITING CUSTOMERS')}</button>
                        </div>
                    </div>
                </div>
                </div>

            </div>
        </>
    )
}
