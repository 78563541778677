import React, { useState } from "react";
import ReliableService from "../../../assets/reliable-services.jpg";
import Service from "../../../assets/services.jpg";
import BestLubricant from "../../../assets/best-lubricant.jpg";
import ServiceTwo from "../../../assets/services_2.jpg";
import ServiceOne from "../../../assets/Services_top_entry.mov";
import GetStarted from "../../../assets/get-started1.jpg";
import { FaCheck } from "react-icons/fa6";

export default function Services() {
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadedImageURLs, setUploadedImageURLs] = useState([]);

  const handleFileChange = (event) => {
    setImages(Array.from(event.target.files));
  };

  const title = "first product";
  const uploadImages = async () => {
    if (images.length === 0) {
      alert("Please select images to upload");
      return;
    }

    if (images.length > 4) {
      alert("You can upload a maximum of 4 images");
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("title", title);
    images.forEach((image, index) => {
      formData.append(`images[${index}]`, image);
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/add-product`, {
        method: "POST",

        body: formData,
      });

      if (!response.ok) {
        throw new Error("Upload failed");
      }

      const result = await response.json();
      setUploadedImageURLs(result.imageURLs);
      alert("Images uploaded successfully!");
    } catch (error) {
      console.error("Error uploading images:", error);
      alert("Failed to upload images");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      {/* <h1>It is Get Started Page</h1> */}
      <div className="reliable-services-mnc">
        <div className="container">
          <div className="row reliable-services">
            <div className="col-xl-6 col-md-12 col-sm-12 video-left">
            <video src={ServiceOne} width="850" height="480" controls={false} autoPlay muted playsInline loop/>

            </div>
            <div className="col-xl-6 col-md-12 col-sm-12 video-right">
              <h2 className="eco-title">
                Onsite Bulk DEF Delivery and Customized Fleet Solutions
              </h2>
              <p className="text-clear-service">
                Eco Fleet Services also offer onsite Bulk DEF whereby we come to
                your location and deliver your order. We understand the meaning
                of efficiency in fleet management which is our driving force to
                ensure all enterprises whether small or big have specifically
                tailored services that fit their needs. Partner with us today as
                your Vehicle Fluid needs provider and get a chance to experience
                quality services and products at affordable rates.
              </p>
              <div className="clear-choice-btn">
                <button className="bulk-cl">Our Bulk Refill Program</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="reliable-products-home">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-md-12 col-sm-12 pro-service-left">
              <h3 className="title-progress">
                Among the products available for this program include
              </h3>
              <div class="box-pro-icon-cl">
                <div class="check-bx-iconc">
                  <h3 class="title-ic">
                    <FaCheck />
                    Lubricants{" "}
                  </h3>
                  <h3 class="title-ic">
                    <FaCheck />
                    Diesel Exhaust Fluid (DEF)
                  </h3>
                </div>
                <div class="check-bx-icon">
                  <h3 class="title-ic">
                    <FaCheck />
                    Windshield Washer Fluid
                  </h3>
                  <h3 class="title-ic">
                    <FaCheck />
                    Antifreeze
                  </h3>
                </div>
              </div>
              <div class="bottom-content">
                <p className="text-pro-cl">
                  Eco Fleet Services offer a timely bulk supply of DEF and
                  Lubricants. Our Bulk refill program is designed to cover all
                  our client’s needs with services ranging from bulk fuel or
                  lubricant delivery, on-site fluids delivery, pumps, and
                  storage tanks.{" "}
                </p>
                <p class="text-pro-cl">
                  This program ensures our clients do not have any downtimes by
                  ensuring delivery for bulk orders either scheduled or on
                  request.
                </p>
              </div>
            </div>
            <div class="col-xl-6 col-md-12 col-sm-12 product-service-right">
              <img src={ServiceTwo} alt="Product" className="img-fluid" />
            </div>
          </div>
          <div className="col-xl-12 col-md- col-sm-12 main-left-service">
            <p className="reliable-content">
              <strong>Scheduled or On-Demand Supply: </strong> We provide both
              scheduled deliveries and on-demand fuel supply to ensure your
              operations run smoothly without any interruptions.
            </p>

            <p className="reliable-content">
              <strong>Commercial Tank Solutions:</strong> For businesses with
              significant fluid requirements, we offer commercial tank solutions
              that include installation, maintenance, and refills, ensuring you
              always have the necessary supplies on hand.
            </p>
          </div>
        </div>
      </div>

      {/* 
      <div className="We-offer-main">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-md- col-sm-12 We-offer-main-left">
              <p className="reliable-content">
             <strong>Scheduled or On-Demand Supply: </strong> We provide both scheduled deliveries and on-demand fuel supply to 
             ensure your operations run smoothly without any interruptions.
              </p>

              <p className="reliable-content">
             <strong>Commercial Tank Solutions:</strong> For businesses with significant fluid requirements, we offer commercial tank solutions that include installation, 
             maintenance, and refills, ensuring you always have the necessary supplies on hand.
              </p>            
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="form-control">
        <input
          type="file"
          className="form-control"
          multiple
          onChange={handleFileChange}
        />
        <button
          className="upload-button"
          onClick={uploadImages}
          disabled={uploading}
        >
          {uploading ? "Uploading..." : "Upload"}
        </button>

        {uploadedImageURLs.length > 0 && (
          <div>
            <h3>Uploaded Images:</h3>
            {uploadedImageURLs.map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Uploaded ${index + 1}`}
                style={{ maxWidth: "100%", maxHeight: "400px", margin: "10px" }}
              />
            ))}
          </div>
        )}
      </div> */}
    </div>
  );
}
