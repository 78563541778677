import React, { useState } from "react";
import "./CustomerLogin.css";
import Header from "../header/Header";
import { useNavigate } from "react-router-dom";
import {
  CustomerLoginApi,
  ForgotPasswordEmail,
} from "../customerDashboardApi/PostApi";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
export default function CustomerLogin() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "" });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // ...........onchange function...................
  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validate fields
    if (name === "email") {
      if (!value) {
        setErrors((prev) => ({ ...prev, email: "Email is required" }));
      } else if (!emailRegex.test(value)) {
        setErrors((prev) => ({ ...prev, email: "Invalid email format" }));
      } else {
        setErrors((prev) => ({ ...prev, email: "" }));
      }
    }

    if (name === "password") {
      if (!value) {
        setErrors((prev) => ({ ...prev, password: "Password is required" }));
      } else {
        setErrors((prev) => ({ ...prev, password: "" }));
      }
    }
  };

  // ..................submit function.................
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData;
    if (!email || !password) {
      if (!email) {
        setErrors((prev) => ({ ...prev, email: "Email is required" }));
      }
      if (!password) {
        setErrors((prev) => ({ ...prev, password: "Password is required" }));
      }
      return;
    }
    try {
      setIsLoading(true);
      const response = await CustomerLoginApi({ email, password });
      if (response.status === 200) {
        toast.success(response.message);
        localStorage.setItem("userEmail", email);
        localStorage.setItem("userToken", response.token);
        localStorage.setItem("refreshToken", response.refreshToken);
        localStorage.setItem("userId", response.userId);
        setFormData("");
        setTimeout(()=>{
          navigate(`/admin/profile`, { state: { email } });
        },3000)
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error, "error");
      toast.error("something went wrong");
    } finally {
      setIsLoading(false);
    }
  };
  // ...................

  // .....................
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    const { email } = formData;
    if (!email) {
      setErrors((prev) => ({ ...prev, email: "Please enter your email address" }));
      return;
    }
    try {
      setIsLoading(true);
      const response = await ForgotPasswordEmail(email);
      console.log(response, "frontend response");
      toast.success("OTP sent successfully");
      setFormData({ email: "" });
      navigate(`/admin/forgot?email=${encodeURIComponent(email)}`);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please try again later");
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div>
      <Header />
      <ToastContainer />
      <div className="login_register">
        <div className="login_inner">
          <form className="form_box">
            <h2>Login your account</h2>
            <div className="fild_box">
              <label>Email Address</label>
              <div className="fild_inner">
                <input
                  type="text"
                  className="custom_form"
                  placeholder="abcd@gmail.com"
                  name="email"
                  required
                  value={formData.email}
                  onChange={onChangeValue}
                />
              </div>
                 {errors.email && <p className="text-danger mb-0 mx-1">{errors.email}</p>}
            </div>
            <div className="fild_box no_botm">
              <label>Password</label>
              <div className="fild_inner position-relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="custom_form"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={onChangeValue}
                />
                <span
                  className="toggle-password text-black fs-4 position-absolute"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
                {errors.password && <p className="text-danger mb-0 mx-1">{errors.password}</p>}
            </div>
            <div className="fild_box forgot_label">
              <p>
                <Link to="/customerRegister">
                  <span>New User?</span>
                </Link>
              </p>
            </div>
            <div className="fild_box_forgot">
              <p>
                <Link to="#" onClick={handleForgotPassword}>
                  <span>Forgot Password?</span>
                </Link>
              </p>
            </div>
            <div className="fild_box">
              <div className="fild_inner">
                <button className="signup_btn" onClick={handleSubmit}>
                  {isLoading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
