import React from 'react'
import ValueIcon7 from '../../../assets/Eco-Fleet-Services.jpg';  
const ClearChoice = () => {
  return (
    <>
     <div className='clear-choice-mnc'>
        <div className='container'>
            <div className='row'>
                <div className='col-xl-6 col-md-12 col-sm-12'>
          <img src={ValueIcon7}></img>
                </div>

                <div className='col-xl-6 col-md-12 col-sm-12 clear-right'>
                    <h2 className='title-clear'>Welcome to Eco Fleet Services</h2>
                    <p className='text-clear'>Eco Fleet Services is your trusted re-seller of <strong>vehicle fluids</strong> and lubricants. For years we have been on the frontline to ensure
                       vehicles running in North America have a constant and reliable supply of high-quality products to enhance performance and operate efficiently. 
                       With our exemplary products and the help of our seasoned vehicle
                       fluid experts, our sole aim is to ensure we are the number one supplier of <strong>Vehicle Fluids and Lubricants in North America</strong>and the surrounding regions.</p>
                       <p className='text-clear'>We offer a wide range of products that ensure your vehicles have access to 
                        quality products in a timely fashion to ensure efficiency, reduced costs, and well as top-notch performance. Our products include:</p>
                   <div className='clear-choice-btn'> <button className='bulk-cl'>Our Bulk Refill PrograM</button></div>
                    </div>
            </div>
        </div>
     </div>
    </>
  )
}

export default ClearChoice
