import React, { useState, useRef, useEffect } from "react";
import Header from "../header/Header";
import "./CustomerOtp.css";
import { ResendOtp, VerifyOtp } from "../customerDashboardApi/PostApi";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
export default function CustomerOtp() {
  const navigate = useNavigate();
  const [OTP, setOtp] = useState(["", "", "", ""]);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [RLoading,SetRloading]=useState(false)
  const inputRefs = useRef([]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const emailFromParams = queryParams.get("email");
    setEmail(emailFromParams);
  }, []);
  const handleOtpChange = (index, value) => {
    if (value.length > 1) {
      value = value.slice(0, 1);
    }

    const newOtp = [...OTP];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < OTP.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleOtpKeyDown = (index, e) => {
    if (e.key === "Backspace" && !OTP[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pastedValue = e.clipboardData.getData("text").slice(0, OTP.length);
    const newOtp = pastedValue.split("");
    setOtp(newOtp);
    inputRefs.current[newOtp.length - 1]?.focus();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (OTP.some((digit) => digit === "")) {
      toast.error("Please enter all digits of the OTP.");
      return; 
    }

    setLoading(true);
    try {
      const response = await VerifyOtp({
        email: email,
        OTP: OTP.join(""),
      });
      if (response.status === 200) {
        toast.success(response.message)
        setTimeout(() => {
          navigate(`/setPassword?email=${encodeURIComponent(email)}`);
        }, 3000)
      }else if(response.status === 404){
          toast.error(response.message)
      }else{
         toast.error(response.message)
      }
    } catch (error) {
      console.error("Error while verifying OTP:", error);
      toast.error("Server error")
    } finally {
      setLoading(false);
    }
  };

  const handleResendOTP = async (e) => {
    e.preventDefault();
    SetRloading(true); 
    try {
      const response = await ResendOtp(email);
      if (response.status === 200) {
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Error while resending OTP:", error);
    } finally {
      SetRloading(false);
    }
  };

  return (
    <div>
      <Header />
      <ToastContainer />
      <div className="login_register">
        <div className="login_inner">
          <form className="form_box" onSubmit={handleSubmit}>
            <h2>Verify your Account</h2>
            <div className="field_box">
              <label>An OTP has been sent to {email}</label>
              <div className="otp-box">
                {OTP.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    className="custom_form_otp"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleOtpKeyDown(index, e)}
                    onPaste={handleOtpPaste}
                    ref={(el) => (inputRefs.current[index] = el)}
                    autoFocus={index === 0}
                  />
                ))}
              </div>
            </div>
            <div className="fild_box">
              <div className="fild_inner">
                <button type="submit" className="signup_btn">
                {loading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
            <button className="signup_btn" onClick={() => navigate("/customerLogin")}>Cancel</button>
            <button className="otp_btn" onClick={handleResendOTP} disabled={loading}>
            {RLoading ? (
                <Spinner animation="border" role="status" />
              ) : (
                "Resend OTP"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
