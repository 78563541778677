import React from "react";
import "./CustomerAdminDashboard/customerDashboardProfile/CustomerProfile.css";
import sadImage from "./assets/sad.png"
export default function Error() {
  return (
    <>
      <div className="login_register error-container">
        <div className="login_inner error-content">
          <div className=" error">
            <div className="sad-icon">
              <img src={sadImage} alt="" />
            </div>
            <h3>404</h3>
            <p className="error-msg">Not Found</p>
          </div>
        </div>
      </div>
    </>
  );
}
