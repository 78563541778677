
import React, { useState, useEffect } from "react";
import { GetAllOrder } from "../customerDashboardApi/PostApi";
import Sidebar from "./SideBar.jsx";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {toast } from "react-hot-toast";

import { getCustomerId } from "../../CustomerDashboard/APIComponents/CartsApi";

export default function OrderList() {
  const [orders, setOrders] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(4);
  const Navigate = useNavigate();


  useEffect(() => {
    const initializeIdentifier = async () => {
      try {
        const storedCustomerId = localStorage.getItem("userId");
        if (storedCustomerId) {
          setCustomerId(storedCustomerId);
        } else {
          const customerIdFromApi = await getCustomerId();
          localStorage.setItem("customerId", customerIdFromApi);
          setCustomerId(customerIdFromApi);
        }
      } catch (error) {
        console.error("Error initializing customer ID:", error);
      }
    };

    initializeIdentifier();
  }, []);

  useEffect(() => {
    if (customerId) {
      const fetchOrders = async () => {
        try {
          const response = await GetAllOrder(customerId);

          if (response.status === 403 || response.message === "Invalid token") {
            toast.error("Unauthorized access. Please log in again.");
            localStorage.removeItem("refreshToken");
            setTimeout(()=>{
             Navigate("/customerLogin")
            },3000)
            
          } 

          if (response?.filteredOrders) {
            setOrders(response.filteredOrders);
          } else {
            console.error("Invalid response format:", response);
          }
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      };

      fetchOrders();
    }
  }, [customerId]);

  const pageCount = Math.ceil(orders.length / itemsPerPage);

  const handlePageChange = (event) => {
    setCurrentPage(event.selected);
  };

  const displayedOrders = orders.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <>
    <Sidebar/>
      {orders.length > 0 ? (
        <>
          <table
            className="table table-striped"
            style={{ width: "70%", marginLeft: "20%", marginTop: "10%" }}
          >
            <thead>
              <tr>
                <th scope="col">Product Image</th>
                <th scope="col">Title</th>
                <th scope="col">Delivery Status</th>
                <th scope="col">Product ID</th>
                <th scope="col">Product Price</th>
              </tr>
            </thead>
            <tbody>
              {displayedOrders.map((order) =>
                order.productDetails && order.productDetails.length > 0 ? (
                  <tr key={order._id}>
                    <td>
                      <Link to={`/admin/orderDetails?orderId=${order._id}`}>
                        {order.productDetails[0]?.productInfo?.productImage ? (
                          <img
                            src={
                              order.productDetails[0].productInfo.productImage
                            }
                            alt={
                              order.productDetails[0].productInfo.productTitle
                            }
                            style={{ height: "100px", width: "50%" }}
                            className="order-img"
                          />
                        ) : (
                          <img
                            // src="path/to/placeholder-image.jpg"
                            alt="Image"
                            style={{ height: "100px", width: "50%" }}
                            className="order-img"
                          />
                        )}
                      </Link>
                    </td>
                    <td>
                      {order.productDetails[0]?.productInfo?.productTitle ||
                        "N/A"}
                    </td>
                    <td>{order.deliveryStatus || "N/A"}</td>
                    <td>
                      {order.productDetails[0]?.orderedProductId || "N/A"}
                    </td>
                    <td>
                      ${order.productDetails[0]?.productInfo?.productPrice ||
                        "N/A"}
                    </td>
                  </tr>
                ) : (
                  <tr key={order._id}>
                    <td colSpan="5">No products available</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          <div style={{ marginLeft: "40%" }}>
            {pageCount > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            )}
          </div>
        </>
      ) : (
        <div style={{ marginLeft: "20%", marginTop: "10%" }}>
          <p>No orders found.</p>
        </div>
      )}
    </>
  );
}
