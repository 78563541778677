import React, { useState, useEffect } from "react";
import "./ChangePassword.css";
import Header from "../header/Header";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmPassword } from "../customerDashboardApi/PostApi";
import Spinner from "react-bootstrap/Spinner";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
export default function SetCustomerPassword() {
  const Navigate = useNavigate()
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPasswords: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [error, setError] = useState("");
  const [confirmError, setConfirmError] = useState("")
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailFromParams = queryParams.get("email");
    setEmail(emailFromParams);
  }, [location.search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setError("");
    setConfirmError("");
    if (name === "password") {
      const passwordError = validatePassword(value);
      if (passwordError) {
        setError(passwordError);
      }
    }
    if (name === "confirmPasswords") {
      if (value !== formData.password) {
        setConfirmError("Password and confirm password should be the same");
      }
    }
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const letterRegex = /[a-zA-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (!password) return "Password is required.";
    if (password.length < minLength) return "Password must be at least 8 characters.";
    if (!letterRegex.test(password)) return "Password must contain at least one letter.";
    if (!numberRegex.test(password)) return "Password must contain at least one number.";
    if (!specialCharRegex.test(password)) return "Password must contain at least one special character.";

    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPasswords } = formData;

    const passwordError = validatePassword(password);
    if (passwordError) {
      return setError(passwordError);
    }
    if (password !== confirmPasswords) {
      return setConfirmError("Password and confirm password should be the same");
    }
    try {
      setIsLoading(true);
      const response = await confirmPassword({ email, password });
      if (response.status === 200) {
        toast.success(response.message);
        setFormData("")
        setTimeout(() => {
          Navigate('/customerLogin')
        }, 3000)
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <ToastContainer />
      <div className="login_register">
        <div className="login_inner">
          <form className="form_box" onSubmit={handleSubmit}>
            <h2 className="change-pas">Set Password</h2>
            <div className="field_box">
              <label>Enter Your New Password</label>
              <div className="field_inner position-relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="custom_form"
                  id="passwordInput"
                  placeholder="#1234567"
                  name="password"
                  onChange={handleChange}
                />
                <span
                  className="toggle-password text-black fs-4 position-absolute"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
              {error && <div className="text-danger mx-1">{error}</div>}
            </div>
            <div className="field_box no_botm position-relative">
              <label>Confirm Password</label>
              <div className="field_inner">
                <input
                  type={showConfirmPassword ? "password" : "text"}
                  className="custom_form"
                  id="confirmPasswordInput"
                  placeholder=""
                  name="confirmPasswords"
                  onChange={handleChange}
                />
                <span
                  className="toggle-password mt-3 text-black fs-4 position-absolute"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>
            {confirmError && <span className="text-danger mx-1">{confirmError}</span>}
            <div className="fild_box forgot_label">
              {/* <p><span>Forgot Password?</span></p> */}
            </div>
            <div className="fild_box">
              <div className="fild_inner">
                <button className="signup_btn" type="submit">
                  {isLoading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
