import React, { useState } from "react";
import "./customerRegister.css";
import Header from "../header/Header";
import { CustomerRegister } from "../customerDashboardApi/PostApi";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function CustomerRegistration() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    userName: "",
    mobile: "",
    email: "",
    companyName: "",
    companyAddress: ""
  });

  const [validationMessages, setValidationMessages] = useState({
    userName: "",
    mobile: "",
    email: "",
    companyName: "",
    companyAddress: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const message = validateField(name, value);
    setValidationMessages((prevMessages) => ({
      ...prevMessages,
      [name]: message,
    }));
  };


  const validateField = (name, value) => {
    let message = "";
    switch (name) {
      case "userName":
        if (!value) {
          message = "UserName is required!";
        } else if (/[^a-zA-Z]/.test(value)) {
          message = "UserName must contain only letters (no numbers or special characters)!";
        } else {
          message = "";
        }
        break;
      case "mobile":
        if (!value) {
          message = "Mobile number is required!";
        } else if (!/^\d{10}$/.test(value)) {
          message = "Mobile number must be exactly 10 digits.";
        } else {
          message = "";
        }
        break;
      case "email":
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value) {
          message = "Email Address is required!";
        } else if (!emailPattern.test(value)) {
          message = "Please enter a valid Email Address!";
        } else {
          message = "";
        }
        break;
      case "companyName":
        message = value ? "" : "Enter your Company Name!";
        break;
      case "companyAddress":
        message = value ? "" : "Enter your Company Address!";
        break;
      default:
        break;
    }
    return message;
  };

  const submitRegister = async (e) => {
    e.preventDefault();

    let hasErrors = false;
    const newValidationMessages = {};

    // Validate each field
    Object.keys(formData).forEach((field) => {
      const message = validateField(field, formData[field]);
      if (message) {
        hasErrors = true;
        newValidationMessages[field] = message;
      }
    });

    // Update validation messages state
    setValidationMessages(newValidationMessages);

    // If there are errors, return early
    if (hasErrors) {
      return; // Exit the function to prevent API call
    }
    try {
      setIsLoading(true);
      const response = await CustomerRegister(formData);
      console.log(response , "response after register")
      if (response.status === 200) {
        setIsLoading(false);
        setFormData({
          userName: "",
          mobile: "",
          email: "",
          companyName: "",
          companyAddress: ""
        });
        setValidationMessages({
          userName: "",
          mobile: "",
          email: "",
          companyName: "",
          companyAddress: ""
        });
        toast.success(response.message);
        setTimeout(() => {
          navigate(`/customerOtp?email=${encodeURIComponent(formData.email)}`);
        }, 3000);
      } else if(response.status === 400) {
        toast.error(response.message,{autoClose : 3000});
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("An occur error");
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <ToastContainer />
      <div className="login_register">
        <div className="login_inner">
          <form className="form_box" onSubmit={submitRegister}>
            <h2 className="register-title">Register your Account</h2>
            {Object.keys(formData).map((field) => (
              <div className="field_box" key={field}>
                <label>{field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}</label>
                <div className="field_inner">
                  <input
                    type={field === "email" ? "email" : "text"}
                    className="custom_form"
                    placeholder={`Please Enter Your ${field.charAt(0).toUpperCase() + field.slice(1)}`}
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                  />
                  {validationMessages[field] && (
                    <span className="text-danger mx-1">{validationMessages[field]}</span>
                  )}
                </div>
              </div>
            ))}
            <div className="fild_box forgot_label">
              <p>
                <Link to="/customerLogin">
                  <span>Already Have An Account?</span>
                </Link>
              </p>
            </div>
            <div className="field_box">
              <div className="field_inner">
                <button className="signup_btn" type="submit">
                  {isLoading ? <Spinner animation="border" role="status" /> : "Submit"}
                </button>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
