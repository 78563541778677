import React from 'react';
import banner2 from '../../../assets/b2.jpg';
import { useTranslation } from 'react-i18next';
import DieselExhaust from '../../../assets/Diesel-Exhaust-Fluid.jpeg';

const products = [
    { id: 1, image: banner2 },
    { id: 2, image: banner2 },
    { id: 3, image: banner2 },
];

export default function ProductDetails() {
    const { t } = useTranslation(); // You can remove i18n if not used

    return (
        <>
            <div className='products-home'>
                <div className='container'>
                    <div className="row">
                        <div className="col-xl-6 col-md-12 col-sm-12 product-service-left">
                            <div className='box-pro-icon-cl'>
                            <div className='check-bx-ic'>
                                <h3 className='title-ic'><i className="fas fa-check"></i> {t('Diesel Exhaust Fluid')}</h3>
                                <h3 className='title-ic'><i className="fas fa-check"></i> {t('Fuel')}</h3>
                                <h3 className='title-ic'><i className="fas fa-check"></i> {t('Lubricants')}</h3>
                                <h3 className='title-ic'><i className="fas fa-check"></i> {t('Antifreeze')}</h3>
                                <h3 className='title-ic'><i className="fas fa-check"></i> {t('Washer fluids')}</h3>
                            </div>
                       
                            <div className="service-right">
                            <p className='service-content'>
                            We have partnered with Fuel and Lubricants manufactures who have crafted the art of producing 
                            vehicle fluids and lubricants that can withstand extreme conditions while ensuring your vehicles operate at peak performance.</p>
                            </div>
                        </div>
                        <div className="bottom-content">
                            <p className='text-pro-cl'>
                            Eco fleet services also offer onsite Bulk DEF whereby we come to your location 
                            and deliver your order. We understand the meaning of efficiency in fleet management
                             which is our driving force to ensure all enterprises whether small or big have specifically tailored services that fit their needs. </p>
                             <p className='text-pro-cl'>
                             Partner with us today as your Vehicle Fluid needs provider and get a chance to experience quality services and products at affordable rates. </p>
                            {/* Example button commented out */}
                            {/* <button className="bulk-cl">{t('Our Bulk Refill Program')}</button> */}
                            </div>
                        </div>
                    
                     
                   

                 
                        <div className="col-xl-6 col-md-12 col-sm-12 product-service-right">
                            <img src={DieselExhaust} alt='Product' className="img-fluid" />
                        </div>
              
                    </div>
                </div>
            </div>
        </>
    );
}
