import "./App.css";
import { BrowserRouter } from "react-router-dom";
import RouteManage from "./Routes/RouteManage";
import { useTranslation } from "react-i18next";


function App() {
  const { t } = useTranslation();

  return (
    <div className="App">

      <BrowserRouter>
        <RouteManage />
      </BrowserRouter>
    
    </div>
  );
}

export default App;
