import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import CustomerProfile from "../customerDashboardProfile/CustomerProfile";
import { useLocation, useNavigate } from "react-router-dom";
import { SingleOrderDetails } from "../customerDashboardApi/PostApi";
import Modal from "react-modal";

Modal.setAppElement("#root");

export default function RecentOrderDetail() {
  const location = useLocation();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const fetchOrderDetails = async () => {
    const queryParams = new URLSearchParams(location.search);
    const orderIdFromUrl = queryParams.get("orderId");
    if (orderIdFromUrl) {
      try {
        const response = await SingleOrderDetails(orderIdFromUrl);
        console.log(response,"singleorderapi")
        setOrderDetails(response.order);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [location.search]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const productDetails = orderDetails?.productDetails || [];
 
  const subtotal = productDetails.reduce((acc, productDetail) => {
    const productPriceString = productDetail.product?.productPrice || "$0.00";
    const productPrice =
      parseFloat(productPriceString.replace(/[^0-9.]/g, "")) || 0;
    const quantity = productDetail.quantity || 0;
    return acc + productPrice * quantity;
  }, 0);
 
  const totalDiscount = productDetails.reduce((acc, productDetail) => {
    const discount = parseFloat(productDetail.product?.discount || "0");
    return acc + (discount * productDetail.quantity) / 100;
  }, 0);

  const totalTax = productDetails.reduce((acc, productDetail) => {
    const taxPercentage = parseFloat(productDetail.product?.taxPercentage || "0");
    const productPrice = parseFloat(productDetail.product?.productPrice.replace(/[^0-9.]/g, "")) || 0;
    const quantity = productDetail.quantity || 0;
    return acc + ((productPrice * taxPercentage) / 100) * quantity;
  }, 0);

  const shippingFees = productDetails.reduce((acc, productDetail) => {
    const escFees = parseFloat(productDetail.product?.escFees || "0");
    return acc + (escFees * productDetail.quantity);
  }, 0);

  const totalAmount = subtotal - totalDiscount + totalTax + shippingFees;

  const openModal = (productId) => {
    setSelectedProduct(productId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedProduct(null);
    setQuantity(1);
  };

  const handleCheckout = () => {
    if (selectedProduct) {
      navigate(`/checkout`, {
        state: {
          productId: selectedProduct,
          quantity: quantity,
        },
      });
      closeModal();
    }
  };

  return (
    <>
      <CustomerProfile />
      <Header />
      <section className="order-details">
        <div className="detail-box">
          <div className="detail-headdimg">
            <h3>Order Details</h3>
          </div>
          <div className="detail-contant">
            <p>Order Number: {orderDetails?._id}</p>
            <p>
              Order Date:{" "}
              {orderDetails?.orderDate
                ? new Date(orderDetails.orderDate).toLocaleDateString()
                : "N/A"}
            </p>
            <p>Payment Status: {orderDetails?.paymentStatus}</p>
            <p>Delivery Status: {orderDetails?.deliveryStatus}</p>
            <p>Delivery Method: {orderDetails?.deliveryMethod}</p>
          </div>
        </div>
        <div className="product-box">
          {productDetails.map((productDetail, index) => (
            <div className="product-box-container" key={index}>
              <div className="product-detail-top">
                <div className="Product-heading-img-box">
                  <h3>Product</h3>
                  <div className="product-image">
                    <div className="img-product">
                      <img
                        src={productDetail.product?.productImage}
                        alt={productDetail.product?.productTitle}
                      />
                    </div>
                    <p>{productDetail.product?.productDescription}</p>
                  </div>
                </div>

                <div className="Product-heading-img">
                  <h3>Status</h3>
                  <div className="product-dtl">
                    <p>{orderDetails?.deliveryStatus}</p>
                  </div>
                </div>
                <div className="Product-heading-img">
                  <h3>Qty</h3>
                  <div className="product-dtl">
                    <p>{productDetail.quantity}</p>
                  </div>
                </div>
                <div className="Product-heading-img">
                  <h3>Price</h3>
                  <div className="product-dtl">
                    <p>${productDetail.product?.productPrice || "0.00"}</p>
                  </div>
                </div>
                <div className="Product-heading-img">
                  <h3>Repeat Order</h3>
                  <div className="product-dtl">
                    <button
                      onClick={() => openModal(productDetail.orderedProductId)}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="total-box">
            <div className="amount-box-container">
              <div className="amount-box">
                <div className="total-text">
                  <p>Subtotal:</p>
                </div>
                <div className="total-text">
                  <p>${subtotal.toFixed(2)}</p>
                </div>
              </div>
              <div className="amount-box">
                <div className="total-text">
                  <p>Discount:</p>
                </div>
                <div className="total-text">
                  <p>${totalDiscount.toFixed(2)}</p>
                </div>
              </div>
              <div className="amount-box">
                <div className="total-text">
                  <p>Tax:</p>
                </div>
                <div className="total-text">
                  <p>${totalTax.toFixed(2)}</p>
                </div>
              </div>
              <div className="amount-box">
                <div className="total-text">
                  <p>Shipping Fees:</p>
                </div>
                <div className="total-text">
                  <p>${shippingFees.toFixed(2)}</p>
                </div>
              </div>
              <div className="amount-box">
                <div className="total-text">
                  <p>Total:</p>
                </div>
                <div className="total-text">
                  <h6>${totalAmount.toFixed(2)}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Select Quantity"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Select Quantity</h2>
        <label>
          Quantity:
          <input
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
            min="1"
          />
        </label>
        <button onClick={handleCheckout}>Proceed to Checkout</button>
        <button onClick={closeModal}>Cancel</button>
      </Modal>
    </>
  );
}
