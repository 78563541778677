import React, { useState, useEffect } from "react";
import "./common.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import logo from "../../../assets/img/logo.png";
import { EmailSubscribeApi } from "../../APIComponents/ProductAPIs/GetAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const { t } = useTranslation();


  useEffect(() => {
    // Scroll to top when location changes
    window.scrollTo(0, 0);
  }, [location]);
  // Email Subscribe API function
  const Subscribe = async () => {
    if (!email) {
      toast.error("Please enter your email address.");
      return;
    }
    try {
      const response = await EmailSubscribeApi({ email });
      console.log("Subscribe API Response:", response);
      toast.success("Subscription successful!");
      setEmail("");
    } catch (error) {
      console.error("Error subscribing:", error);
      toast.error("Subscription failed. Please try again later.");
    }
  };

  // Determine if a link is active
  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  // Handle link click and scroll to top
  // const handleLinkClick = (path) => {
  //   navigate(path, { replace: true }); // Ensure the navigation replaces the current entry
  //   setTimeout(() => {
  //     window.scrollTo(0, 0); // Instant scroll to the top of the page
  //   }, 0); // Delay of 0 ms to ensure it runs after navigation
  // };


  const handleLinkClick = (path) => {
    navigate(path);
  };


  return (
    <>
      <ToastContainer />
      <div className="footer">
        <footer className="footer-area mt-5">
          <div className="container">
            <div className="row pb-4">
              <div className="col-md-4 mb-4 text-start p-0">
                <section className="first-section">
                  <div className="footer-logo mb-2">
                    <img
                      src={logo}
                      alt="logo.png"
                      className="img-fluid"
                      width="250px"
                      height="70px"
                      onClick={() => handleLinkClick("/")}
                    />
                  </div>
                </section>
              </div>
              <div className="col-md-4 mb-4 text-start p-0">
                <section className="sec-section">
                  <div className="footer-linking">
                    <Link to="/" className={`d-block text-white mb-2 ${isActive('/')}`} onClick={() => handleLinkClick('/')}>
                      {t('Home')}
                    </Link>
                    <Link to="/Why-us" className={`d-block text-white mb-2 ${isActive('/Why-us')}`} onClick={() => handleLinkClick('/Why-us')}>
                      {t('Why Us')}
                    </Link>
                    <Link to="/all-products" className={`d-block text-white mb-2 ${isActive('/all-products')}`} onClick={() => handleLinkClick('/all-products')}>
                      {t('Products')}
                    </Link>
                    <Link to="/Services" className={`d-block text-white mb-2 ${isActive('/Services')}`} onClick={() => handleLinkClick('/Services')}>
                      {t('Services')}
                    </Link>
                    <Link to="/Contact" className={`d-block text-white mb-2 ${isActive('/Contact')}`} onClick={() => handleLinkClick('/Contact')}>
                      {t('Contact')}
                    </Link>
                  </div>
                </section>
              </div>
              <div className="col-md-4 mb-4 text-start p-0">
                <section className="third-section">
                  <div className="footer-address">
                    <p> {t("Subscribe to our emails")}</p>
                    <div className="email_container">
                      <div className="mail-area input-group mb-5">
                        <input
                          type="email"
                          className="form-control"
                          placeholder={t("Email Address")}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={Subscribe}
                        >
                          {t("SUBSCRIBE")}
                        </button>
                      </div>
                    </div>

                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="footer-policy">
            <div className="container">
              <div>
                {/* <div className="border"></div> */}
                <p className="mt-3">{t("CopyRight-footer")}</p>
              </div>
              <div className="social-linking-area">
                <Link to="https://facebook.com" target="blank" className="text-white me-2">
                  <FaFacebook style={{ fontSize: "30px" }} />
                </Link>
                <Link to="https://twitter.com" target="blank" className="text-white me-2">
                  <FaTwitter style={{ fontSize: "30px" }} />
                </Link>
                <Link to="https://instagram.com" target="blank" className="text-white">
                  <FaInstagram style={{ fontSize: "30px" }} />
                </Link>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
